import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";

const Posts = () => {
  const [posts, setPosts] = useState([]);
  //const state = {posts: []};

  

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch(
        "https://serverless-api.pnklshh.workers.dev/posts"
      );
      const postsResp = await resp.json();
      setPosts(JSON.parse(postsResp));
    };
    getPosts();
  }, []);

  return (
    <html lang="en">
      <head>
        <meta charset="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"/>
        <script type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script type="text/javascript" src="https://code.jquery.com/ui/1.12.1/jquery-ui.js" integrity="sha256-T0Vest3yCU7pafRw9r+settMBX6JkKN06dqBnpQ8d30=" crossorigin="anonymous"></script>
        <script type="text/javascript" src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
      </head>

      <div class='container-fluid' style={{height: '100%'}}>
        {posts.map((post) => (
          <div class="row" style={{display: 'flex', justifyContent: 'center'}}>
            <div class="post" style={{height: '400px', width: '60%', border: '1px solid black', marginTop:'2%'}}>
              <div key={post.title}>
                <h4>
                  {post.title}
                </h4>
              </div>
              <div class="post-body">
                <h5>{post.content}</h5>
              </div>

            </div>
          </div>
        ))}
      </div>
    </html>
  );
};

export default Posts;